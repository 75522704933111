import React from 'react';
import { Card, Button } from 'react-bootstrap';

const TwitterProfileComp = () => {
    return (
            <Button variant="primary" href="https://twitter.com/KingGlimStone" target="_blank" rel="noopener noreferrer">
                Find the clues here
            </Button>
    );
};

export default TwitterProfileComp;