import React, {useState, FormEvent} from 'react';
import { validatePassword } from './dndHuntClient';
import { useNavigate } from 'react-router';
import {Container, Row, Col, Form, Button, Alert} from 'react-bootstrap';
import TwitterProfileComp from './TwitterProfileComp';

const HomePage = () => {
    const [inputText, setInputText] = useState("");
    const [answerIncorrect, setAnswerIncorrect] = useState(false); 
    const navigate = useNavigate();
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setInputText(event.target.value);
    };
  
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // Call API Endpoint and validate password
      //If it is correct we route to the new page.
      const response = await validatePassword(inputText);
      if (response.answer) {
        //Todo: Route to page
        setAnswerIncorrect(false);
        navigate('/finalSolution', {state: {bearer: response.bearerToken}});
      } else {
        setAnswerIncorrect(true);
      }
      console.log(response);
    };
  
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <Col>
          <Row>
            <Col>
              <h3>You seem confused on my travel notes, solve "caution punt" to find a hint, period</h3>
            </Col>
          </Row>
          <Row>
            <h3>Because Elon managed to ruin DND also</h3>
          </Row>
          <Row>
            <Col>
              <embed src="https://firebasestorage.googleapis.com/v0/b/dnd-hunt.appspot.com/o/Puzzle1.pdf?alt=media&token=bbd6ec80-6367-4931-ae7a-905f284024bb" type="application/pdf" width="100%" height="600px" />
            </Col>
            <Col>
              <embed src="https://firebasestorage.googleapis.com/v0/b/dnd-hunt.appspot.com/o/TravelNotesPuzzle2.pdf?alt=media&token=88cadf2d-1ba9-43b9-bcf5-31c9538a2e17" type="application/pdf" width="100%" height="600px" />
            </Col>
          </Row>
          <Row>
            <TwitterProfileComp/>
          </Row>
          <Row>
            {answerIncorrect && 
              <Alert variant="primary">
                Incorrect Please try again
              </Alert>
            }
          </Row>
          <Row className='m-2'>
            <Col className='text-center'>
              <h1>
                Enter the code to find Gnomegarde
              </h1>
            </Col>
          </Row>
            <Form onSubmit={handleSubmit} className="custom-form">
              <Row>
                <Form.Group controlId="formInput" className="mb-0">
                  <Form.Control
                    type="text"
                    placeholder="Enter text"
                    value={inputText}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Button variant="primary" className="btn-primary" type="submit">
                  Submit
                </Button>
              </Row>
            </Form>
        </Col>
      </Container>
    );
};

export default HomePage;