
import './App.css';
import HomePage from './HomePage';
import MapPage from './MapPage';
import { useRoutes } from 'react-router-dom';

function App() {


  let element = useRoutes([
    {
      path: "/",
      element: <HomePage/>
    },
    {
      path: "/finalSolution",
      element: <MapPage/>
    }
  ])

  return (
    <div>
      {element}
    </div>
  )
}

export default App;
