import React, { useEffect, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { getMap } from './dndHuntClient';


const MapPage = () => {
    const {state} = useLocation();
    const [mapURL, setMapURL] = useState("");
    const [loaded, setLoaded]= useState(false);
    const noMeme = "https://firebasestorage.googleapis.com/v0/b/dnd-hunt.appspot.com/o/JurrassicParkMeme.gif?alt=media&token=8a5b0f33-6f66-45da-97f5-ea52beb29c7f";
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getMap(state.bearer);
                setMapURL(response.imageUrl)
            } catch {
                
            }
            setLoaded(true);
        }

        fetchData();
    })

    return (
        <Container className="d-flex align-items-center justify-content-center">
            {loaded ? <img src={mapURL ? mapURL : noMeme} alt="GnomenGarde Map" className='img-fluid'/> : <Spinner animation="border" variant="primary" />}
        </Container>
    )

};

export default MapPage;