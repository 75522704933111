//For Running Locally
//const baseUrl = "http://127.0.0.1:5001/dnd-hunt/us-central1/app";
const baseUrl = "https://us-central1-dnd-hunt.cloudfunctions.net/app"

type PasswordResponse = {
    answer: Boolean,
    bearerToken: string 
};

type MapResponse = {
    imageUrl: string
};


async function validatePassword(password: String): Promise<PasswordResponse> {
    const body = {
        "password": password
    };
    const response = await fetch(baseUrl + '/submitAnswer', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw new Error("Failed to validate password");
    }
    
    const responseData = await response.json();
    return responseData;
}

async function getMap(bearerToken: String): Promise<MapResponse> {
    const response = await fetch(baseUrl + '/map', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${bearerToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Failed to get Map");
    }

    const responseData = await response.json();
    return responseData;
}

export { validatePassword, getMap }